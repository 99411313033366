import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import ruLocale from 'date-fns/locale/ru';
import s from 'styled-components';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';

import 'react-datepicker/dist/react-datepicker.css';
import 'assets/scss/components/_custom-datepicker.scss';

import { Box } from 'layout';
import { Text } from 'components/typo';
import { MONTHS } from 'helpers/Date';

// helpers
// import { formatDateDiary } from 'helpers/Date'
registerLocale('ru', ruLocale); // register it with the name you want

const getYear = (date) => date.getFullYear();
const getMonth = (date) => date.getMonth();

const DatepickerContainer = s(DatePicker)`
  display: flex;

  .arrow {
    color: ${({ theme }) => theme.colors.grey500};
    transition: color 0.3s ease-in-out;

    svg {
      fill: currentColor;
    }
  }
`;

const renderCustomHeader = ({
  date,
  // changeYear,
  // changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => {
  const year = getYear(date);
  const month = MONTHS[getMonth(date)];

  return (
    <Box
      ai="center"
      jc="space-between"
      p="16px"
    >
      <button
        className="arrow arrow-prev"
        disabled={prevMonthButtonDisabled}
        type="button"
        onClick={decreaseMonth}
      >
        <ChevronLeft />
      </button>

      <Box>
        <Text fw="m">{month}</Text>
        &nbsp;
        <Text color="grey500">{year}</Text>
      </Box>

      <button
        className="arrow arrow-next"
        disabled={nextMonthButtonDisabled}
        type="button"
        onClick={increaseMonth}
      >
        <ChevronRight />
      </button>
    </Box>
  );
};

const Datepicker = ({
  dateFormat = 'd MMMM, yyyy H:mm',
  timeIntervals = 15,
  timeFormat = 'HH:mm',
  showTimeSelect = false,
  customInput = null,
  inline = true,
  ...props
}) => (
  <div className="custom-datepicker">
    <DatepickerContainer
      {...props}
      disabledKeyboardNavigation
      customInput={customInput}
      dateFormat={dateFormat}
      inline={inline}
      locale="ru"
      showTimeSelect={showTimeSelect}
      timeCaption="Время"
      timeFormat={timeFormat}
      timeIntervals={timeIntervals}
      renderCustomHeader={renderCustomHeader}
    // showMonthDropdown
    // showYearDropdown
    // adjustDateOnChange
    />
  </div>
);

export default Datepicker;
