import { ProductsActionTypes } from 'redux/types/ProductsTypes';
import { UserActionTypes } from 'redux/types/UserTypes';

const INITIAL_STATE = {
  name: 'products',
  searchList: null,
  modalList: [],
  modalListIsEmpty: [],
  isFetching: true,
  product: null,
  isFetchingProduct: false,
  isFetchingToggleActive: false,
  isSubmitting: false,
};

const productsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UserActionTypes.USER_LOGOUT_SUCCESS:
    return { ...INITIAL_STATE };

  case ProductsActionTypes.SET_FETCHING_PRODUCTS:
    return {
      ...state,
      isFetching: action.payload,
    };

  case ProductsActionTypes.FETCH_PRODUCTS_SEARCH_SUCCESS:
    return {
      ...state,
      searchList: action.payload,
    };

  case ProductsActionTypes.CLEAR_PRODUCTS_SEARCH:
    return {
      ...state,
      searchList: null,
    };

  case ProductsActionTypes.FETCH_PRODUCTS_MODAL_SUCCESS:
    return {
      ...state,
      modalList: action.payload,
    };

  case ProductsActionTypes.SET_PRODUCTS_MODAL_IS_EMPTY:
    return {
      ...state,
      modalListIsEmpty: action.payload,
    };

  case ProductsActionTypes.CLEAR_PRODUCTS_MODAL:
    return {
      ...state,
      modalList: [],
      modalListIsEmpty: false,
    };

  case ProductsActionTypes.SET_FETCHING_PRODUCT_SUBMITTING:
    return {
      ...state,
      isSubmitting: action.payload,
    };

  case ProductsActionTypes.SET_FETCHING_PRODUCT_TOGGLE_ACTIVE:
    return {
      ...state,
      isFetchingToggleActive: action.payload,
    };

  case ProductsActionTypes.FETCH_PRODUCT_TOGGLE_ACTIVE_SUCCESS:
    return {
      ...state,
      searchList: [
        ...state.searchList.map((p) => (p.id === action.payload.id
          ? action.payload
          : p)),
      ],
    };

  case ProductsActionTypes.SET_FETCHING_GET_PRODUCT_BY_ID:
    return {
      ...state,
      isFetchingProduct: action.payload,
    };

  case ProductsActionTypes.FETCH_GET_PRODUCT_BY_ID_SUCCESS:
    return {
      ...state,
      product: action.payload,
    };

  case ProductsActionTypes.CLEAR_PRODUCT_BY_ID:
    return {
      ...state,
      product: null,
    };

  default:
    return state;
  }
};

export default productsReducer;
