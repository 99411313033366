// import { toast } from 'react-toastify'

// types
// import { UserActionTypes } from 'redux/types/UserTypes'

// services
// import ApiService from 'services/ApiService'
import { fetchLogin } from './AuthActions';
import StorageService from 'services/StorageService';
import store from 'redux/store/configureStore';
import { setFetchingSettingsSubmitting } from './SettingsActions';
import ApiService from 'services/ApiService';
import { UserActionTypes } from 'redux/types/UserTypes';

// constants
// import * as PathsTypes from 'constants/PathsTypes'
// import { messageTypes } from 'constants/NotifierTypes'
// import { setNotification } from './SystemActions'

export const getUserSession = (callback) => {
  const token = StorageService.getToken();

  if (token) {
    store.dispatch(fetchLogin(token, false))
      .then((user) => callback(user));
  }
  else {
    callback(null);
  }
};

export const setFetchingProfileUpdate = (value) => ({
  type: UserActionTypes.SET_FETCHING_PROFILE_UPDATE,
  payload: value,
});

export const fetchProfileUpdateSuccess = (profile) => ({
  type: UserActionTypes.FETCH_PROFILE_UPDATE_SUCCESS,
  payload: profile,
});

export const fetchProfileUpdateError = (error) => ({
  type: UserActionTypes.FETCH_PROFILE_UPDATE_ERROR,
  payload: error,
});

export const fetchProfileUpdate = (data = {}, upload = false) => (dispatch) => {
  dispatch(setFetchingSettingsSubmitting(true));

  return new Promise((res, rej) => ApiService.apiCall({
    method: 'PATCH',
    url: `${ApiService.paths.user.PROFILE_EDIT}`,
    isToken: true,
    data,
    multipart: upload,
  })
    .then((response) => {
      dispatch(fetchProfileUpdateSuccess(response));
      dispatch(setFetchingSettingsSubmitting(false));

      return res(response);
    })
    .catch(({ data }) => {
      dispatch(fetchProfileUpdateError(false));

      return rej(data);
    })
    .finally(() => {
      dispatch(setFetchingSettingsSubmitting(false));
    }));
};
