import React, {
  useRef,
  useEffect,
} from 'react';
import s from 'styled-components';
import { gsap } from 'services/GsapService';

const ModalSidebarWrapperContainer = s.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: ${({ $zIndex }) => $zIndex || 9999};
  display: ${({ $show }) => ($show ? 'block' : 'none')};
  width: 100%;
  height: 100vh;

  .inner,
  .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .inner {
    z-index: 2;
    display: flex;
    justify-content: flex-end;
    transform: translate(-100%, 0, 0);
    opacity: 0;
    pointer-events: none;
  }

  .backdrop {
    z-index: 1;
    background-color: rgba(45, 55, 72, 0.3);
    opacity: 0;
  }
`;

const ModalSidebarWrapper = (props) => {
  const {
    show = false,
    closeModal = () => {},
    component = null,
    children = null,
    zIndex,
    onOpenStart = () => {},
    onCloseStart = () => {},
    isBlocked = false,
    onBlocked = () => {},
    isNeedClose = false,
  } = props;

  const innerRef = useRef(null);
  const backdropRef = useRef(null);

  useEffect(() => {
    if (isNeedClose) {
      handleCloseModal();
    }
    // eslint-disable-next-line
  }, [ isNeedClose ])

  useEffect(() => {
    if (innerRef.current && backdropRef.current) {
      if (show) {
        const tl = gsap.timeline();

        document.body.classList.add('noscroll');
        onOpenStart();

        tl.fromTo(backdropRef.current, {
          duration: 0.3,
          alpha: 0,
        }, { alpha: 1 }, '0.3')
          .fromTo(innerRef.current, {
            duration: 0.1,
            alpha: 0,
          }, { alpha: 1 }, 0)
          .fromTo(innerRef.current, {
            duration: 0.4,
            x: '100%',
          }, { x: '0' }, 0.2);
      }
    }
    // eslint-disable-next-line
  }, [show, innerRef.current, backdropRef.current])

  const renderModal = (props) => {
    if (!component && !show) return null;

    const ModalComponent = component;

    return (
      <ModalComponent
        {...props}
        closeModal={handleCloseModal}
      />
    );
  };

  const handleCloseModal = (callback) => {
    const tl = gsap.timeline();

    onCloseStart();

    tl.fromTo(backdropRef.current, {
      duration: 0.3,
      alpha: 1,
    }, {
      alpha: 0,
      onComplete() {
        closeModal();
        document.body.classList.remove('noscroll');

        if (callback && typeof callback === 'function') {
          callback();
        }
      },
    }, '0.1')
      .fromTo(innerRef.current, {
        duration: 0.4,
        x: '0',
        alpha: 1,
      }, {
        x: '100%',
        alpha: 0,
      }, '0');
  };

  return (
    <ModalSidebarWrapperContainer
      $show={show}
      $zIndex={zIndex}
    >
      <div
        ref={backdropRef}
        className="backdrop"
        role="button"
        tabIndex={0}
        onClick={(e) => {
          e.stopPropagation();

          if (isBlocked) {
            onBlocked();
          }
          else {
            handleCloseModal();
          }
        }}
      />
      <div
        ref={innerRef}
        className="inner"
      >
        {show && children}
        {renderModal && renderModal(props)}
      </div>
    </ModalSidebarWrapperContainer>
  );
};

export default ModalSidebarWrapper;
