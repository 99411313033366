import { ReactComponent as IconMoney } from 'assets/icons/payment/money.svg';
import { ReactComponent as IconCard } from 'assets/icons/payment/card.svg';
import { ReactComponent as IconCardOnline } from 'assets/icons/payment/card-online.svg';

export const OrderStatuses = {
  ERROR: 'ERROR',
  WAITING_CLIENT: 'WAITING_CLIENT',
  WAITING_PAYMENT: 'WAITING_PAYMENT',
  WAITING_COMPANY: 'WAITING_COMPANY',
  IN_PROCESS: 'IN_PROCESS',
  READY: 'READY',
  ON_ADDRESS: 'ON_ADDRESS',
  DELIVERED: 'DELIVERED',
  COMPLETED: 'COMPLETED',
  REJECTED_CLIENT: 'REJECTED_CLIENT',
  REJECTED_COMPANY: 'REJECTED_COMPANY',
  ON_WAY: 'ON_WAY',
  NOT_DELIVERED: 'NOT_DELIVERED',
};

export const OrderCutlery = {
  spoons: 'spoons',
  sticks: 'sticks',
  forks: 'forks',
};

export const OrderCutleryModel = {
  [OrderCutlery.spoons]: 'Ложки',
  [OrderCutlery.sticks]: 'Палочки',
  [OrderCutlery.forks]: 'Вилки',
};

export const OrderPayments = {
  CASH_FACT: 'CASH_FACT',
  CARD_FACT: 'CARD_FACT',
  CARD_ONLINE: 'CARD_ONLINE',
};

export const OrderPaymentModel = {
  [OrderPayments.CASH_FACT]: {
    id: [ OrderPayments.CASH_FACT ],
    title: 'Наличными курьеру',
    titlePickup: 'Наличными при получении',
    icon: IconMoney,
  },
  [OrderPayments.CARD_FACT]: {
    id: [ OrderPayments.CARD_FACT ],
    title: 'Картой курьеру',
    titlePickup: 'Картой при получении',
    icon: IconCard,
  },
  [OrderPayments.CARD_ONLINE]: {
    id: [ OrderPayments.CARD_ONLINE ],
    title: 'Онлайн картой',
    icon: IconCardOnline,
  },
};

export const OrderModes = {
  TABLE: 'TABLE',
  DELIVERY: 'DELIVERY',
  PICKUP: 'PICKUP',
};

export const OrderModesModel = {
  [OrderModes.DELIVERY]: 'Доставка',
  [OrderModes.PICKUP]: 'Самовывоз',
  [OrderModes.TABLE]: 'За стол',
};

export const OrdersFiltersModel = {
  [OrderModes.DELIVERY]: {
    value: OrderModes.DELIVERY,
    label: OrderModesModel[OrderModes.DELIVERY],
  },
  [OrderModes.PICKUP]: {
    value: OrderModes.PICKUP,
    label: OrderModesModel[OrderModes.PICKUP],
  },
  [OrderModes.TABLE]: {
    value: OrderModes.TABLE,
    label: OrderModesModel[OrderModes.TABLE],
  },
};

export const ordersStatusesModel = {
  [OrderStatuses.ERROR]: {
    value: OrderStatuses.ERROR,
    label: 'Ошибка',
    variant: 'red',
  },
  [OrderStatuses.WAITING_COMPANY]: {
    value: OrderStatuses.WAITING_COMPANY,
    label: 'Новый',
    variant: 'red',
  },
  [OrderStatuses.IN_PROCESS]: {
    value: OrderStatuses.IN_PROCESS,
    label: 'Готовим',
    variant: 'red',
  },
  [OrderStatuses.READY]: {
    value: OrderStatuses.READY,
    label: 'Готов',
    variant: 'red',
  },
  [OrderStatuses.ON_WAY]: {
    value: OrderStatuses.ON_WAY,
    label: 'Доставка',
    variant: 'red',
  },
  [OrderStatuses.ON_ADDRESS]: {
    value: OrderStatuses.ON_ADDRESS,
    label: 'Ждет гостя',
    variant: 'red',
  },
  [OrderStatuses.DELIVERED]: {
    value: OrderStatuses.DELIVERED,
    label: 'Доставлен',
    variant: 'red',
  },
  [OrderStatuses.COMPLETED]: {
    value: OrderStatuses.COMPLETED,
    label: 'Выполнен',
    variant: 'grey',
  },
  [OrderStatuses.REJECTED_COMPANY]: {
    value: OrderStatuses.REJECTED_COMPANY,
    label: 'Отменен',
    variant: 'grey',
  },
  [OrderStatuses.REJECTED_CLIENT]: {
    value: OrderStatuses.REJECTED_CLIENT,
    label: 'Отказ',
    variant: 'grey',
  },
  [OrderStatuses.NOT_DELIVERED]: {
    value: OrderStatuses.NOT_DELIVERED,
    label: 'Не выполнен',
    variant: 'grey',
  },
};
