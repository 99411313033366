import { ANALYTICS_TYPES } from 'constants/AnalyticsTypes';
import { AnalyticsActionTypes } from 'redux/types/AnalyticsTypes';
import { UserActionTypes } from 'redux/types/UserTypes';

const getInitAnalytics = () => Object.values(ANALYTICS_TYPES)
  .reduce((acc, key) => {
    acc[key] = null;

    return acc;
  }, {});

const INITIAL_STATE = {
  name: 'analytics',
  list: getInitAnalytics(),
  isFetching: Object.values(ANALYTICS_TYPES)
    .reduce((acc, key) => {
      acc[key] = false;

      return acc;
    }, {}),
};

const analyticsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UserActionTypes.USER_LOGOUT_SUCCESS:
    return { ...INITIAL_STATE };

  case AnalyticsActionTypes.RESET_ANALYTICS:
    return {
      ...state,
      list: getInitAnalytics(),
    };

  case AnalyticsActionTypes.SET_FETCHING_ANALYTICS:
    return {
      ...state,
      isFetching: {
        ...state.isFetching,
        [action.payload.type]: action.payload.value,
      },
    };

  case AnalyticsActionTypes.FETCH_ANALYTICS_SUCCESS:
    return {
      ...state,
      list: {
        ...state.list,
        [action.payload.type]: action.payload.value,
      },
    };

  default:
    return state;
  }
};

export default analyticsReducer;
