export const CategoriesActionTypes = {
  SET_FETCHING_CATEGORIES: 'SET_FETCHING_CATEGORIES',
  FETCH_CATEGORIES_ERROR: 'FETCH_CATEGORIES_ERROR',
  FETCH_CATEGORIES_SUCCESS: 'FETCH_CATEGORIES_SUCCESS',
  CLEAR_CATEGORIES: 'CLEAR_CATEGORIES',

  SET_FETCHING_CATEGORY: 'SET_FETCHING_CATEGORY',
  FETCH_CATEGORY_SUCCESS: 'FETCH_CATEGORY_SUCCESS',
  FETCH_CATEGORY_ERROR: 'FETCH_CATEGORY_ERROR',

  SET_FETCHING_GET_CATEGORY_BY_ID: 'SET_FETCHING_GET_CATEGORY_BY_ID',
  FETCH_GET_CATEGORY_BY_ID_SUCCESS: 'FETCH_GET_CATEGORY_BY_ID_SUCCESS',

  SET_CATEGORY: 'SET_CATEGORY',

  SET_FETCHING_CATEGORY_SUBMITTING: 'SET_FETCHING_CATEGORY_SUBMITTING',
  SET_FETCHING_CATEGORY_TOGGLE_ACTIVE: 'SET_FETCHING_CATEGORY_TOGGLE_ACTIVE',
  SET_CATALOG_CATEGORIES_OPENED_IDS: 'SET_CATALOG_CATEGORIES_OPENED_IDS',

  FETCH_CATEGORY_CREATE_SUCCESS: 'FETCH_CATEGORY_CREATE_SUCCESS',
  FETCH_CATEGORY_UPDATE_SUCCESS: 'FETCH_CATEGORY_UPDATE_SUCCESS',
  FETCH_CATEGORY_DELETE_SUCCESS: 'FETCH_CATEGORY_DELETE_SUCCESS',
  FETCH_CATEGORY_TOGGLE_ACTIVE_SUCCESS: 'FETCH_CATEGORY_TOGGLE_ACTIVE_SUCCESS',
  SET_FETCHING_CATEGORY_TOGGLE_PRODUCT_ACTIVE_SUCCESS: 'SET_FETCHING_CATEGORY_TOGGLE_PRODUCT_ACTIVE_SUCCESS',
  FETCH_CATEGORY_TOGGLE_PRODUCT_ACTIVE_SUCCESS: 'FETCH_CATEGORY_TOGGLE_PRODUCT_ACTIVE_SUCCESS',
};
