import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from 'redux';
// import logger from 'redux-logger'
import thunk from 'redux-thunk';
import * as Sentry from '@sentry/react';
import rootReducer from '../reducers/rootReducer';

const middleware = [ thunk ];

const isDevelopment = process.env.NODE_ENV === 'development';

if (isDevelopment) {
  // middleware.push(logger)
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(...middleware),
    sentryReduxEnhancer,
    isDevelopment
      ? window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : (f) => f
      : (f) => f,
  ),
);

export default store;
