/* eslint-disable indent */
/* eslint-disable import/no-cycle */
import React from 'react';
import s from 'styled-components';
import cn from 'classnames';
import { Text } from 'components/typo';
import Switcher from 'components/Switcher';
import WithHover from 'components/hooks/WithHover';

const FormSwitchCardContainer = s.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${({ $padding }) => ($padding || '22px 18px')};
  text-align: center;
  border: 1px solid ${({ theme }) => theme.colors.grey300};
  border-radius: ${({ theme }) => theme.radius.normal};
  cursor: ${({ $isDisabled }) => ($isDisabled ? 'initial' : 'pointer')};

  &.--border-off {
    border: unset;
    border-radius: unset;
  }

  &.--hor {
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    padding: ${({ $padding }) => ($padding || '28px 21px')};

    .w-icon {
      margin-right: 14px;
      margin-bottom: 0;
    }

    .w-title {
      min-width: 30%;
      padding-right: 20px;
      margin-bottom: 0;
    }

    .w-description {
      margin-right: auto;
      margin-bottom: 0;
    }
  }

  .w-icon {
    margin-bottom: 8px;
    transition: fill 0.3s ease-in-out;
    fill:
      ${(
        {
          theme,
          $value,
          $isDisabled,
        },
      ) => theme.colors[$value && !$isDisabled ? 'grey700' : 'grey500']};
  }

  &:hover {
    .w-icon {
      fill: ${({
theme,
$isDisabled,
}) => ($isDisabled ? '' : theme.colors.grey700)};
    }

    .w-title {
      color: ${({
theme,
$isDisabled,
}) => ($isDisabled ? '' : theme.colors.dark)};
    }
  }

  .w-title {
    margin-bottom: 6px;
    color:
      ${(
        {
          theme,
          $value,
          $isDisabled,
        },
      ) => theme.colors[$value && !$isDisabled ? 'dark' : 'grey500']};
  }

  .w-description {
    margin-bottom: 18px;
  }
`;

const FormSwitchCard = ({
  title,
  description,
  icon: Icon = null,
  name,
  value,
  handleChange = null,
  isDisabled,
  isHovered,
  isFocused,
  noBorders,
  padding,
  isHorizontal = false,
  ...cardProps
}) => {
  const toggleEventHandler = () => {
    if (handleChange) {
      handleChange(!value);
    }
  };

  return (
    <FormSwitchCardContainer
      className={cn('w-switch-card', {
        '--hor': isHorizontal,
        '--border-off': noBorders,
      })}
      $isDisabled={isDisabled}
      $value={value}
      $padding={padding}
      $isFocused={isFocused}
      onClick={toggleEventHandler}
      {...cardProps}
    >
      {Icon && (
        <Icon
          className="w-icon"
          height={24}
          width={24}
        />
      )}
      <Text
        transitionOn
        className="w-title"
        color="current"
        fw="sb"
      >
        {title}
      </Text>
      {description && (
        <Text
          className="w-description"
          color="grey500"
          size="small"
        >
          {description}
        </Text>
      )}
      <Switcher
        isDisabled={isDisabled}
        isHovered={isHovered}
        name={name}
        value={value}
        onChange={handleChange}
      />
    </FormSwitchCardContainer>
  );
};

export default WithHover(FormSwitchCard);
