import { useOutsideAlerter } from 'hooks/useOutsideAlerter';
import React, { useRef } from 'react';

/**
 * Component that alerts if you click outside of it
 */
export default function OutsideAlerter(props) {
  const {
    className,
    children,
  } = props;
  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef, props);

  return (
    <div
      ref={wrapperRef}
      className={className || ''}
    >
      {children}
    </div>
  );
}
