export const SettingsActionTypes = {
  SET_FETCHING_SETTINGS_SUBMITTING: 'SET_FETCHING_SETTINGS_SUBMITTING',
  SET_FETCHING_SETTINGS_DATA: 'SET_FETCHING_SETTINGS_DATA',

  FETCH_COMPANY_UPDATE_SUCCESS: 'FETCH_COMPANY_UPDATE_SUCCESS',
  FETCH_COMPANY_APP_UPDATE_SUCCESS: 'FETCH_COMPANY_APP_UPDATE_SUCCESS',

  FETCH_SCHEDULE_CREATE_SUCCESS: 'FETCH_SCHEDULE_CREATE_SUCCESS',
  FETCH_SCHEDULE_UPDATE_SUCCESS: 'FETCH_SCHEDULE_UPDATE_SUCCESS',
  FETCH_SCHEDULE_DELETE_SUCCESS: 'FETCH_SCHEDULE_DELETE_SUCCESS',

  FETCH_COMPANY_SOCIAL_LINK_UPDATE_SUCCESS: 'FETCH_COMPANY_SOCIAL_LINK_UPDATE_SUCCESS',

  SET_FETCHING_GET_COMPANY_PAYMENTS: 'SET_FETCHING_GET_COMPANY_PAYMENTS',
  FETCH_GET_COMPANY_PAYMENTS_SUCCESS: 'FETCH_GET_COMPANY_PAYMENTS_SUCCESS',
  FETCH_TOGGLE_COMPANY_PAYMENT_SUCCESS: 'FETCH_TOGGLE_COMPANY_PAYMENT_SUCCESS',

  SET_FETCHING_GET_COUNTRIES: 'SET_FETCHING_GET_COUNTRIES',
  FETCH_GET_COUNTRIES_SUCCESS: 'FETCH_GET_COUNTRIES_SUCCESS',
};
