import { UserActionTypes } from '../types/UserTypes';
import { AuthActionTypes } from '../types/AuthTypes';
import { SettingsActionTypes } from '../types/SettingsTypes';

const INITIAL_STATE = {
  profile: null,
  auth: false,
  isFetching: {
    auth: false,
    submit: false,
  },
  isLoader: false,
  error: null,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UserActionTypes.USER_REGISTER_START:
  case UserActionTypes.USER_LOGIN_START:
  case UserActionTypes.USER_LOGOUT_START:
  case UserActionTypes.USER_RESET_PASSWORD_START:
  case UserActionTypes.USER_RESET_PASSWORD_SUBMIT_START:
  case UserActionTypes.USER_SET_NEW_PASSWORD_START:
    return {
      ...state,
      error: null,
      isFetching: {
        ...state.isFetching,
        auth: true,
      },
    };

  case UserActionTypes.USER_RESET_PASSWORD_SUCCESS:
  case UserActionTypes.USER_RESET_PASSWORD_SUBMIT_SUCCESS:
  case UserActionTypes.USER_SET_NEW_PASSWORD_SUCCESS:
  case UserActionTypes.USER_REGISTER_SUCCESS:
    return {
      ...state,
      isFetching: {
        ...state.isFetching,
        auth: false,
      },
    };

  case UserActionTypes.SET_SUBMITING:
  case UserActionTypes.SET_FETCHING_ACCOUNT_UPDATE:
    return {
      ...state,
      isFetching: {
        ...state.isFetching,
        submit: action.payload,
      },
    };

  case UserActionTypes.FETCH_ACCOUNT_UPDATE_SUCCESS:
    return {
      ...state,
      profile: {
        ...state.profile,
        company: {
          ...state.profile.company,
          ...action.payload,
        },
      },
    };

  case UserActionTypes.USER_LOGIN_SUCCESS:
  case AuthActionTypes.FETCH_LOGIN_SUCCESS:
    return {
      ...state,
      profile: action.payload,
      auth: true,
      isFetching: {
        ...state.isFetching,
        auth: false,
      },
    };

  case AuthActionTypes.FETCH_LOGOUT_SUCCESS:
  case UserActionTypes.USER_LOGOUT_SUCCESS:
    return { ...INITIAL_STATE };

  case UserActionTypes.USER_REGISTER_ERROR:
  case UserActionTypes.USER_LOGIN_ERROR:
  case UserActionTypes.USER_LOGOUT_ERROR:
  case UserActionTypes.USER_RESET_PASSWORD_ERROR:
  case UserActionTypes.USER_RESET_PASSWORD_SUBMIT_ERROR:
  case UserActionTypes.USER_SET_NEW_PASSWORD_ERROR:
    return {
      ...state,
      isFetching: {
        ...state.isFetching,
        auth: false,
      },
      error: action.payload,
    };

  case SettingsActionTypes.FETCH_SCHEDULE_CREATE_SUCCESS:
    return {
      ...state,
      profile: {
        ...state.profile,
        company: {
          ...state.profile.company,
          schedule_elems: [
            ...state.profile.company.schedule_elems,
            action.payload,
          ],

        },
      },
    };

  case SettingsActionTypes.FETCH_SCHEDULE_UPDATE_SUCCESS:
    return {
      ...state,
      profile: {
        ...state.profile,
        company: {
          ...state.profile.company,
          schedule_elems: [
            ...state.profile.company.schedule_elems.map((elem) => (elem.id === action.payload.id
              ? action.payload
              : elem)),
          ],
        },
      },
    };

  case SettingsActionTypes.FETCH_SCHEDULE_DELETE_SUCCESS:
    return {
      ...state,
      profile: {
        ...state.profile,
        company: {
          ...state.profile.company,
          schedule_elems: [ ...state.profile.company.schedule_elems.filter((elem) => (elem.id !== action.payload)) ],
        },
      },
    };

  case SettingsActionTypes.FETCH_COMPANY_UPDATE_SUCCESS:
    return {
      ...state,
      profile: {
        ...state.profile,
        company: {
          ...state.profile.company,
          ...action.payload,
        },
      },
    };

  case UserActionTypes.FETCH_PROFILE_UPDATE_SUCCESS:
    return {
      ...state,
      profile: action.payload,
    };

  case SettingsActionTypes.FETCH_COMPANY_APP_UPDATE_SUCCESS:
    return {
      ...state,
      profile: {
        ...state.profile,
        company: {
          ...state.profile.company,
          company_app: {
            ...state.profile.company.company_app,
            ...action.payload,
          },
        },
      },
    };

  case SettingsActionTypes.FETCH_COMPANY_SOCIAL_LINK_UPDATE_SUCCESS:
    return {
      ...state,
      profile: {
        ...state.profile,
        company: {
          ...state.profile.company,
          social_links: [
            ...state.profile.company.social_links.map((elem) => (elem.id === action.payload.id
              ? action.payload
              : elem)),
          ],
        },
      },
    };

  default:
    return state;
  }
};

export default userReducer;
