import React from 'react';
import s from 'styled-components';
import cn from 'classnames';

const gutters = {
  xl: 36,
  big: 20,
  normal: 15,
  small: 12,
  xs: 5,
};

const renderDefaultStyle = ({
  $display = 'flex',
  $dir = 'row',
  $m = 'unset',
  $p = 'unset',
  $ai = 'flex-start',
  $jc = 'flex-start',
  $width,
}) => `
  width: ${$width};
  display: ${$display};
  flex-direction: ${$dir};
  align-items: ${$ai};
  justify-content: ${$jc};
  margin: ${$m};
  padding: ${$p};
`;

const renderRowStyles = ({
  $dir = 'row',
  $flex,
  $gutter,
}) => `
  & > * {
    flex: ${$flex ? 1 : 'unset'};
    margin-right: ${$dir === 'row' ? gutters[$gutter] || $gutter : 0}px !important;
    margin-bottom: ${$dir === 'column' ? gutters[$gutter] || $gutter : 0}px !important;

    &:last-child {
      ${$dir === 'column' ? 'margin-bottom: 0' : 'margin-right: 0'} !important;
    }
  }
`;

const renderGridStyles = ({
  $gutter = 'small',
  $offsetYOff = false,
  $itemWidth,
}) => `
  flex-direction: row;
  flex-wrap: wrap;
  margin: ${$offsetYOff ? `0 -${gutters[$gutter]}px` : `-${gutters[$gutter]}px`};

  & > * {
    width: ${$itemWidth || '33.333%'};
    padding: ${$offsetYOff ? `0 ${gutters[$gutter]}px` : `${gutters[$gutter]}px`};
  }
`;

const GridContainerDiv = s.div`
  ${(props) => renderDefaultStyle(props)}
  ${({
    $grid,
    ...props
  }) => ($grid
    ? renderGridStyles(props)
    : renderRowStyles(props))}
`;

const GridContainerSpan = s.span`
${(props) => renderDefaultStyle(props)}
${({
    $grid,
    props,
  }) => ($grid
    ? renderGridStyles(props)
    : renderRowStyles(props))}
`;

const Grid = ({
  children,
  width = '100%',
  className,
  as = 'div',
  grid = false,
  gutter = 'big',
  offsetYOff = false,
  display,
  dir,
  flex,
  m,
  p,
  ai,
  jc,
  itemWidth,
  ...otherProps
}) => {
  const classes = cn('w-grid', className);

  const props = {
    className: classes,
    $width: width,
    $grid: grid,
    $gutter: gutter,
    $display: display,
    $dir: dir,
    $m: m,
    $p: p,
    $ai: ai,
    $flex: flex,
    $jc: jc,
    $itemWidth: itemWidth,
    $offsetYOff: offsetYOff,
    ...otherProps,
  };

  if (as === 'span') {
    return (
      <GridContainerSpan {...props}>
        {children}
      </GridContainerSpan>
    );
  }

  return (
    <GridContainerDiv {...props}>
      {children}
    </GridContainerDiv>
  );
};

export default Grid;
