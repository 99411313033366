import { toast } from 'react-toastify';
import { SystemActionTypes } from '../types/SystemTypes';
import { messageTypes } from 'constants/NotifierTypes';
import { setMessage } from 'components/base/NotifierMessage';
import ApiService from 'services/ApiService';

// types
// import { LIMIT_INIT } from 'redux/types/FiltersTypes'
import StorageService from 'services/StorageService';
// import { fetchGetProducts } from './ProductsActions'
// import { fetchGetCategoriesFilter } from './CategoriesActions'

export const setShowPreloader = (value) => ({
  type: SystemActionTypes.SET_SHOW_PRELOADER,
  payload: value,
});

const initStateSuccess = (value) => ({
  type: SystemActionTypes.INIT_STATE_SUCCESS,
  payload: value,
});

export const loadInitState = () => (dispatch) => {
  dispatch(setShowPreloader(false));

  return new Promise((res, rej) => {
    Promise.all([])
      .then(() => {
        dispatch(setShowPreloader(false));
        dispatch(initStateSuccess());
        res();
      })
      .catch(() => {
        dispatch(setShowPreloader(false));
        rej();
      });
  });
};

export const setBrowserScrollWidth = (value) => ({
  type: SystemActionTypes.SET_BROWSER_WIDTH,
  payload: value,
});

// const initStateError = (error) => ({
//   type: SystemActionTypes.INIT_STATE_ERROR,
//   payload: error,
// })

export const setSearchValue = (value) => ({
  type: SystemActionTypes.SET_SEARCH_VALUE,
  payload: value,
});

export const setModal = (payload) => ({
  type: SystemActionTypes.SET_MODAL,
  payload,
});

export const setFormIsDirty = (payload) => ({
  type: SystemActionTypes.SET_FORM_IS_DIRTY,
  payload,
});

export const setPanelIsCollapsed = (payload) => {
  StorageService.setInterfacePanelIsCollapsed(payload);

  return {
    type: SystemActionTypes.SET_PANNEL_IS_COLLAPSED,
    payload,
  };
};

export const setNotification = ({
  type = 'message',
  ...otherProps
}) => (dispatch) => {
  dispatch({ type: SystemActionTypes.SET_NOTIFICATION });
  toast(setMessage(otherProps), { type });
};

function displayObjErrors(data) {
  Object.keys(data)
    .forEach((errorKey) => {
      const errors = data[errorKey];

      errors.forEach((message) => {
        toast(setMessage({ message: `${errorKey}: ${JSON.stringify(message)}` }), { type: messageTypes.ERROR });
      });
    });
}

export const setNotificationError = (data = {}) => (dispatch) => {
  if (typeof data === 'object') {
    const {
      non_field_errors: errorMessages,
      detail,
    } = data;

    dispatch({ type: SystemActionTypes.SET_NOTIFICATION_ERROR });

    if (errorMessages) {
      errorMessages.forEach((message) => {
        toast(setMessage({ message }), { type: messageTypes.ERROR });
      });
    }
    else if (detail) {
      toast(setMessage({ message: detail }), { type: messageTypes.ERROR });
    }
    else if (Array.isArray(data)) {
      data.forEach((message) => {
        toast(setMessage({ message }), { type: messageTypes.ERROR });
      });
    }
    else if (typeof data === 'object') {
      displayObjErrors(data);
    }
    else {
      toast(setMessage({ message: JSON.stringify(data) }), { type: messageTypes.ERROR });
    }
  }
  else if (typeof data === 'string') {
    toast(setMessage({ message: JSON.stringify(data) }), { type: messageTypes.ERROR });
  }
};

export const setFetchingFormRequest = (user) => ({
  type: SystemActionTypes.SET_FETCHING_FORM_REQUEST,
  payload: user,
});

export const fetchFormRequestSuccess = (error) => ({
  type: SystemActionTypes.FETCH_FORM_REQUEST_SUCCESS,
  payload: error,
});

export const setNeedToSaveForm = (value) => ({
  type: SystemActionTypes.SET_NEED_TO_SAVE_FORM,
  payload: value,
});

export const fetchFormRequesе = (data) => (dispatch) => {
  dispatch(setFetchingFormRequest(true));

  return new Promise((res, rej) => ApiService.apiCall({
    url: ApiService.paths.form.REQUEST,
    method: 'POST',
    data,
  })
    .then((response) => {
      dispatch(setFetchingFormRequest(false));
      dispatch(fetchFormRequestSuccess());

      dispatch(setNotification({
        message: 'Заявка отправлена успешно',
        type: messageTypes.MESSAGE,
      }));
      dispatch(setModal({ close: true }));

      return res(response);
    })
    .catch(({ data }) => {
      dispatch(setFetchingFormRequest(false));

      return rej(data);
    }));
};
