export const ANALYTICS_TYPES = {
  AVGBILL: 'avgbill',
  CLIENTS: 'clients',
  INCOME: 'income',
  INCOME_PIE: 'income_pie',
  ORDERS: 'orders',
};

export const AnalyticsGroupBy = {
  MONTH: 'MONTH',
  DAY: 'DAY',
  HOUR: 'HOUR',
};

export const AnalyticsPeriodsFilters = {
  YEAR: 'YEAR',
  QUART: 'QUART',
  MONTH: 'MONTH',
  WEEK: 'WEEK',
  DAY: 'DAY',
};

export const AnalyticsPeriodsFiltersModel = {
  [AnalyticsPeriodsFilters.YEAR]: {
    value: AnalyticsPeriodsFilters.YEAR,
    label: 'Год',
    groupBy: AnalyticsGroupBy.MONTH,
  },
  [AnalyticsPeriodsFilters.QUART]: {
    value: AnalyticsPeriodsFilters.QUART,
    label: 'Квартал',
    groupBy: AnalyticsGroupBy.MONTH,
  },
  [AnalyticsPeriodsFilters.MONTH]: {
    value: AnalyticsPeriodsFilters.MONTH,
    label: 'Месяц',
    groupBy: AnalyticsGroupBy.DAY,
  },
  [AnalyticsPeriodsFilters.WEEK]: {
    value: AnalyticsPeriodsFilters.WEEK,
    label: 'Неделя',
    groupBy: AnalyticsGroupBy.DAY,
  },
  [AnalyticsPeriodsFilters.DAY]: {
    value: AnalyticsPeriodsFilters.DAY,
    label: 'День',
    groupBy: AnalyticsGroupBy.HOUR,
  },
};
