import { combineReducers } from 'redux';
import additions from './AdditionsReducer';
import analytics from './AnalyticsReducer';
import auth from './AuthReducer';
import stories from './StoriesReducer';
import categories from './CategoriesReducer';
import clients from './ClientsReducer';
import filters from './FiltersReducer';
import locations from './LocationsReducer';
import mailings from './MailingsReducer';
import orders from './OrdersReducer';
import products from './ProductsReducer';
import user from './UserReducer';
import settings from './SettingsReducer';
import system from './SystemReducer';

const rootReducer = combineReducers({
  additions,
  analytics,
  auth,
  stories,
  categories,
  clients,
  filters,
  locations,
  mailings,
  orders,
  products,
  user,
  settings,
  system,
});

export default rootReducer;
