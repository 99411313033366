import * as Yup from 'yup';
import { isFuture } from 'date-fns';
import {
  emailRegExp,
  phoneReg,
  urlRegExp,
  timeRegExpStatic,
  urlRegExpNoProtocol,
} from 'helpers/RegExp';

export const isEmail = (value) => emailRegExp.test(value);

export const isPhone = (value) => phoneReg.test(value);

Yup.addMethod(Yup.object, 'atLeastOneOf', function (list) {
  return this.test({
    name: 'atLeastOneOf',
    message: 'Заполните одно из полей',
    exclusive: true,
    params: { keys: list.join(', ') },
    test: (value) => value == null || list.some((f) => value[f] != null),
  });
});

Yup.addMethod(Yup.string, 'isNeedVendor', function () {
  return this.test({
    name: 'isNeedVendor',
    message: 'Укажите артикул продукта в iiko',
    exclusive: true,
    test(value) {
      if (value === null || value === undefined || value.length > 0) {
        return true;
      }

      return this.createError({ message: 'Укажите артикул продукта в iiko' });
    },
  });
});

Yup.addMethod(Yup.string, 'EmailOrPhone', function () {
  return this.test({
    name: 'EmailOrPhone',
    message: 'Неверно указан Email или Телефон',
    exclusive: true,
    test(value) {
      if (isEmail(value) || isPhone(value)) return true;

      return this.createError({ message: 'Неправильно указан Email или Телефон' });
    },
  });
});

function siblingValidation(value, siblingName, that) {
  const siblingValue = that.parent[siblingName];

  if (!value && !siblingValue) return true;

  if (siblingValue !== null && String(siblingValue).length > 0 && !value) {
    return that.createError({ message: 'Заполните поле' });
  }

  return true;
}

Yup.addMethod(Yup.string, 'additionIsValid', function (siblingName) {
  return this.test({
    name: 'additionIsValid',
    message: 'Заполните поле',
    exclusive: true,
    test(value) {
      return siblingValidation(value, siblingName, this);
    },

  });
});

Yup.addMethod(Yup.number, 'additionIsValid', function (siblingName) {
  return this.test({
    name: 'additionIsValid',
    message: 'Заполните поле',
    exclusive: true,
    test(value) {
      return siblingValidation(value, siblingName, this);
    },
  });
});

function scheduleValidation(value, siblingName, that) {
  const siblingValue = that.parent[siblingName];

  if (!timeRegExpStatic.test(value) || (!!siblingValue && String(siblingValue).length > 0 && !value)) {
    return that.createError({ message: 'Заполните поле' });
  }

  return true;
}

Yup.addMethod(Yup.string, 'scheduleIsValid', function (siblingName) {
  return this.test({
    name: 'scheduleIsValid',
    message: 'Заполните поле',
    exclusive: true,
    test(value) {
      return scheduleValidation(value, siblingName, this);
    },
  });
});

const phoneSchema = Yup.string()
  .matches(phoneReg, 'Неправильный формат телефона')
  .required('Укажите телефон');

const emailSchema = Yup.string()
  .email('Неправильный формат почты')
  .required('Заполните поле');

export const SchemaLogin = Yup.object()
  .shape({
    username: Yup.string()
      .email('Неправильный формат почты')
      .required('Введите Email'),
    password: Yup.string()
      .min(4, 'Пароль должен быть динной больше 4 символа')
      .required('Введите пароль'),
  });

export const SchemaPhone = Yup.object()
  .shape({
    phone: Yup.string()
      .matches(phoneReg, 'Неправильный формат телефона')
      .required('Укажите телефон'),
  });

export const SchemaCatalogProductAdd = Yup.object()
  .shape({
    title: Yup.string()
      .required('Заполните поле'),
    description: Yup.string('Заполните поле'),
    vendor_code: Yup.string()
      .isNeedVendor()
      .nullable(),
    category: Yup.string()
      .required(),
    formats: Yup.array()
      .of(Yup.object()
        .shape({
          title: Yup.string()
            .required('Заполните поле'),
          amount: Yup.number()
            .typeError('Значение должно быть числом')
            .nullable(),
          amount_measure: Yup.string()
            .typeError('Значение должно быть числом'),
          price: Yup.number()
            .typeError('Значение должно быть числом')
            .required('Заполните поле'),
          raw_price: Yup.number()
            .additionIsValid('discount')
            .nullable(),
          discount: Yup.number()
            .additionIsValid('raw_price')
            .nullable(),
        })),
  });

export const SchemaCatalogCategoryAdd = Yup.object()
  .shape({
    title: Yup.string()
      .required('Заполните поле'),
    position: Yup.number()
      .typeError('Значение должно быть числом')
      .nullable(),
  });

export const SchemaCatalogStoriesAdd = Yup.object()
  .shape({
    name: Yup.string()
      .required('Заполните поле'),
    image: Yup.string()
      .nullable()
      .required('Добавьте изображение'),
  });

export const SchemaCatalogGroupStory = Yup.object()
  .shape({
    name: Yup.string()
      .max(46, 'Максимально 46 символов')
      .required(),
    image: Yup.string()
      .nullable()
      .required(),
  });

export const SchemaCatalogStoryAdd = Yup.object()
  .shape({
    group: Yup.number()
      .required('Заполните поле'),
    title: Yup.string(),
    text: Yup.string(),
    image: Yup.string()
      .nullable(),
    video: Yup.string()
      .nullable(),
    link_button: Yup.string()
      .max(200, 'Максимально 200 символов')
      .matches(urlRegExpNoProtocol, 'Неправильный формат ссылки')
      .nullable(),
    button: Yup.string()
      .nullable(),
  })
  .test(
    'match',
    'Добавьте изображение или видео',
    function (parent) {
      const {
        video,
        image,
      } = parent;
      const { createError } = this;

      console.log(parent);

      if (!video && !image) {
        return createError({
          message: 'Добавьте изображение или видео',
          path: 'video',
        });
      }

      return true;
    },
  );

export const SchemaAdditionAdd = Yup.object()
  .shape({
    title: Yup.string()
      .required('Заполните поле'),
    price: Yup.number()
      .typeError('Значение должно быть числом')
      .required('Заполните поле'),
    vendor_code: Yup.string()
      .isNeedVendor()
      .nullable(),
  });

export const SchemaSettingsAccount = Yup.object()
  .shape({
    first_name: Yup.string()
      .required('Заполните поле'),
  });

export const SchemaFormRequest = Yup.object()
  .shape({
    company: Yup.string()
      .required('Укажите название компании'),
    phone: Yup.string()
      .matches(phoneReg, 'Неправильный формат телефона')
      .required('Укажите телефон'),
    email: Yup.string()
      .email('Неправильный формат почты')
      .required('Укажите почту'),
    terms: Yup.bool()
      .test(
        'match',
        'Требуется ваше согласие',
        (terms) => terms === true,
      ),
  });

export const SchemaFormQuestion = Yup.object()
  .shape({
    email: Yup.string()
      .email('Неправильный формат почты')
      .required('Укажите почту'),
    message: Yup.string()
      .required('Заполните поле'),
  });

export const SchemaChangePassword = Yup.object()
  .shape({
    oldPassword: Yup.string()
      .required('Введите старый пароль'),
    newPassword: Yup.string()
      .min(1, 'Минимальная длинна 1 символ')
      .required('Введите новый пароль'),
    passwordConfirm: Yup.string()
      .oneOf([
        Yup.ref('newPassword'),
        null,
      ], 'Пароли должны совпадать')
      .required('Повторите новый пароль'),
  });

export const SchemaSettingsLocation = Yup.object()
  .shape({
    city: Yup.string()
      .required('Заполните поле'),
    address: Yup.string()
      .required('Заполните поле'),
    number: Yup.number()
      .required('Заполните поле'),
    building: Yup.string()
      .nullable(),
    latitude: Yup.string()
      .required('координаты не найдены'),
    longitude: Yup.string()
      .required('координаты не найдены'),
    min_order_price: Yup.string()
      .required('Заполните поле'),
    min_delivery_time: Yup.string()
      .required('Заполните поле'),
    delivery_price: Yup.string()
      .required('Заполните поле'),
    order_price_free: Yup.string()
      .required('Заполните поле'),
    schedule: Yup.array()
      .of(
        Yup.object()
          .shape({
            week_day: Yup.number(),
            time_from: Yup.string()
              .scheduleIsValid('time_to'),
            time_to: Yup.string()
              .scheduleIsValid('time_from'),
            is_active: Yup.boolean(),
          }),
      ),
  });

export const SchemaTime = Yup.string()
  .required('Заполните поле')
  .test(
    'match',
    'Укажите корректное время',
    function (time) {
      const {
        parent: { date },
        createError,
      } = this;

      if (!date) {
        return createError({
          message: 'Укажите дату',
          path: 'date',
        });
      }

      if (time && time.length === 5) {
        const setDate = new Date(date);
        const [
          h,
          m,
        ] = time.split(':');

        setDate.setHours(h);
        setDate.setMinutes(m);

        return isFuture(setDate);
      }

      return false;
    },
  );

export const SchemaAddMailing = Yup.object()
  .shape({
    title: Yup.string()
      .required('Заполните поле')
      .max(56, 'Максимально 56 символов'),
    description: Yup.string()
      .max(256, 'Максимально 269 символов'),
    date: Yup.string()
      .required('Заполните поле'),
    time: SchemaTime,
  });

export const SchemaChangeTime = Yup.object()
  .shape({
    date: Yup.string()
      .required('Заполните поле'),
    time: SchemaTime,
  });

export const SchemaSettingsMain = Yup.object()
  .shape({
    title: Yup.string()
      .max(56, 'Максимально 56 символов')
      .required('Заполните поле'),
    phone: phoneSchema,
    email: emailSchema,
    socials: Yup.array()
      .of(Yup.object()
        .shape({
          link: Yup.string()
            .matches(urlRegExp, 'Неправильный формат ссылки')
            .nullable(),
        })),
  });
