import React from 'react';
import * as PathsTypes from 'constants/PathsTypes';
import { Link } from 'components/typo';

const WhatsupLink = ({
  color = 'white',
  hover = 'whitet70',
}) => (
  <Link
    browser
    color={color}
    hover={hover}
    href={PathsTypes.WHATSUP}
    target="_blank"
  >
    WhatsApp
  </Link>
);

export default WhatsupLink;
