import React from 'react';
import {
  useField,
  useFormikContext,
} from 'formik';
import { FormDropdown } from 'components/form';
import Datepicker from 'components/Datepicker';
import { formatDate } from 'helpers/Date';

const FormFieldDatepicker = ({
  placeholder,
  defaultStyle = 'field',
  formatter = 'dd MMMM yyyy',
  size = 'big',
  position,
  isTop,
  readOnly = false,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [ field ] = useField(props);

  return (
    <FormDropdown
      defaultStyle={defaultStyle}
      isError={field.error}
      isTop={isTop}
      readOnly={readOnly}
      isTouched={field.touched}
      placeholder={placeholder}
      position={position}
      renderDropdown={({ closeDropdown }) => (
        <Datepicker
          {...field}
          {...props}
          selected={(field.value && new Date(field.value)) || null}
          onChange={(val) => {
            setFieldValue(field.name, val);
            closeDropdown();
          }}
        />
      )}
      size={size}
      value={field.value ? formatDate(field.value, formatter) : ''}
    />
  );
};

export default FormFieldDatepicker;
