import { SettingsActionTypes } from '../types/SettingsTypes';
import ApiService from 'services/ApiService';

export const setFetchingSettingsData = (value) => ({
  type: SettingsActionTypes.SET_FETCHING_SETTINGS_DATA,
  payload: value,
});

export const setFetchingSettingsSubmitting = (value) => ({
  type: SettingsActionTypes.SET_FETCHING_SETTINGS_SUBMITTING,
  payload: value,
});

export const fetchScheduleCreateSuccess = (settings) => ({
  type: SettingsActionTypes.FETCH_SCHEDULE_CREATE_SUCCESS,
  payload: settings,
});

export const fetchScheduleCreate = (data) => (dispatch) => {
  dispatch(setFetchingSettingsSubmitting(true));

  return new Promise((res, rej) => ApiService.apiCall({
    method: 'POST',
    url: `${ApiService.paths.company.SCHEDULE}`,
    data,
    isToken: true,
  })
    .then((response) => {
      dispatch(fetchScheduleCreateSuccess(response));
      dispatch(setFetchingSettingsSubmitting(false));

      return res(response);
    })
    .catch(({ data }) => {
      dispatch(setFetchingSettingsSubmitting(false));

      return rej(data);
    }));
};

export const fetchScheduleUpdateSuccess = (settings) => ({
  type: SettingsActionTypes.FETCH_SCHEDULE_UPDATE_SUCCESS,
  payload: settings,
});

export const fetchScheduleUpdate = (id, data) => (dispatch) => {
  dispatch(setFetchingSettingsSubmitting(true));

  return new Promise((res, rej) => ApiService.apiCall({
    method: 'PATCH',
    url: `${ApiService.paths.company.SCHEDULE}${id}/`,
    data,
    isToken: true,
  })
    .then((response) => {
      dispatch(fetchScheduleUpdateSuccess(response));
      dispatch(setFetchingSettingsSubmitting(false));

      return res(response);
    })
    .catch(({ data }) => {
      dispatch(setFetchingSettingsSubmitting(false));

      return rej(data);
    }));
};

export const fetchScheduleDeleteSuccess = (settings) => ({
  type: SettingsActionTypes.FETCH_SCHEDULE_DELETE_SUCCESS,
  payload: settings,
});

export const fetchScheduleDelete = (id) => (dispatch) => {
  dispatch(setFetchingSettingsSubmitting(true));

  return new Promise((res, rej) => ApiService.apiCall({
    method: 'DELETE',
    url: `${ApiService.paths.company.SCHEDULE}${id}/`,
    isToken: true,
  })
    .then((response) => {
      dispatch(fetchScheduleDeleteSuccess(id));
      dispatch(setFetchingSettingsSubmitting(false));

      return res(response);
    })
    .catch(({ data }) => {
      dispatch(setFetchingSettingsSubmitting(false));

      return rej(data);
    }));
};

export const fetchCompanyUpdateSuccess = (settings) => ({
  type: SettingsActionTypes.FETCH_COMPANY_UPDATE_SUCCESS,
  payload: settings,
});

export const fetchCompanyUpdate = (data) => (dispatch) => {
  dispatch(setFetchingSettingsSubmitting(true));

  return new Promise((res, rej) => ApiService.apiCall({
    method: 'PATCH',
    url: `${ApiService.paths.company.UPDATE}`,
    isToken: true,
    data,
    multipart: true,
  })
    .then((response) => {
      dispatch(fetchCompanyUpdateSuccess(response));
      dispatch(setFetchingSettingsSubmitting(false));

      return res(response);
    })
    .catch(({ data }) => {
      dispatch(setFetchingSettingsSubmitting(false));

      return rej(data);
    }));
};

export const fetchCompanyAppUpdateSuccess = (settings) => ({
  type: SettingsActionTypes.FETCH_COMPANY_APP_UPDATE_SUCCESS,
  payload: settings,
});

export const fetchCompanyAppUpdate = (data) => (dispatch) => {
  dispatch(setFetchingSettingsSubmitting(true));

  return new Promise((res, rej) => ApiService.apiCall({
    method: 'PATCH',
    url: `${ApiService.paths.app.UPDATE}`,
    isToken: true,
    data,
  })
    .then((response) => {
      dispatch(fetchCompanyAppUpdateSuccess(response));
      dispatch(setFetchingSettingsSubmitting(false));

      return res(response);
    })
    .catch(({ data }) => {
      dispatch(setFetchingSettingsSubmitting(false));

      return rej(data);
    }));
};

export const fetchCompanySocialLinkUpdateSuccess = (settings) => ({
  type: SettingsActionTypes.FETCH_COMPANY_SOCIAL_LINK_UPDATE_SUCCESS,
  payload: settings,
});

export const fetchCompanySocialLinkUpdate = (data) => (dispatch) => {
  dispatch(setFetchingSettingsSubmitting(true));

  return new Promise((res, rej) => ApiService.apiCall({
    method: 'PUT',
    url: `${ApiService.paths.company.LINKS}${data.id}/`,
    isToken: true,
    data,
  })
    .then((response) => {
      dispatch(fetchCompanySocialLinkUpdateSuccess(response));
      dispatch(setFetchingSettingsSubmitting(false));

      return res(response);
    })
    .catch(({ data }) => {
      dispatch(setFetchingSettingsSubmitting(false));

      return rej(data);
    }));
};

export const fetchGetAppMenuOptionsSuccess = (settings) => ({
  type: SettingsActionTypes.FETCH_GET_APP_MENU_OPTIONS_SUCCESS,
  payload: settings,
});

export const fetchGetAppMenuOptions = () => (dispatch) => {
  dispatch(setFetchingSettingsData(true));

  return new Promise((res, rej) => ApiService.apiCall({
    url: ApiService.paths.app.MENU_OPTIONS,
    isToken: true,
  })
    .then((response) => {
      dispatch(fetchGetAppMenuOptionsSuccess(response.results));
      dispatch(setFetchingSettingsData(false));

      return res(response);
    })
    .catch(({ data }) => {
      dispatch(setFetchingSettingsData(false));

      return rej(data);
    }));
};

export const fetchToggleAppMenuOptionSuccess = (settings) => ({
  type: SettingsActionTypes.FETCH_TOGGLE_APP_MENU_OPTION_SUCCESS,
  payload: settings,
});

export const fetchToggleAppMenuOptions = (option) => (dispatch) => {
  dispatch(setFetchingSettingsSubmitting(true));

  return new Promise((res, rej) => ApiService.apiCall({
    method: 'POST',
    url: ApiService.paths.app.MENU_OPTION_TOGGLE(option),
    isToken: true,
    data: {},
  })
    .then((response) => {
      dispatch(fetchToggleAppMenuOptionSuccess(response));
      dispatch(setFetchingSettingsSubmitting(false));

      return res(response);
    })
    .catch(({ data }) => {
      dispatch(setFetchingSettingsSubmitting(false));

      return rej(data);
    }));
};

export const setFetchingGetAppThemes = (value) => ({
  type: SettingsActionTypes.SET_FETCHING_GET_APP_THEMES,
  payload: value,
});

export const fetchGetAppThemesSuccess = (themes) => ({
  type: SettingsActionTypes.FETCH_GET_APP_THEMES_SUCCESS,
  payload: themes,
});

export const fetchGetAppThemes = () => (dispatch) => {
  dispatch(setFetchingGetAppThemes(true));

  return new Promise((res, rej) => ApiService.apiCall({
    url: ApiService.paths.app.THEMES,
    isToken: true,
  })
    .then((response) => {
      dispatch(fetchGetAppThemesSuccess(response.results));
      dispatch(setFetchingGetAppThemes(false));

      return res(response);
    })
    .catch(({ data }) => {
      dispatch(setFetchingGetAppThemes(false));

      return rej(data);
    }));
};

export const setFetchingGetCountries = (value) => ({
  type: SettingsActionTypes.SET_FETCHING_GET_COUNTRIES,
  payload: value,
});

export const fetchGetCountriesSuccess = (countries) => ({
  type: SettingsActionTypes.FETCH_GET_COUNTRIES_SUCCESS,
  payload: countries,
});

export const fetchGetCountries = () => (dispatch) => {
  dispatch(setFetchingGetCountries(true));

  return new Promise((res, rej) => ApiService.apiCall({
    url: ApiService.paths.countries.ROOT,
    isToken: true,
  })
    .then((response) => {
      dispatch(fetchGetCountriesSuccess(response.results));
      dispatch(setFetchingGetCountries(false));

      return res(response);
    })
    .catch(({ data }) => {
      dispatch(setFetchingGetCountries(false));

      return rej(data);
    }));
};

export const setFetchingGetGetCompanyPayments = (payments) => ({
  type: SettingsActionTypes.SET_FETCHING_GET_COMPANY_PAYMENTS,
  payload: payments,
});

export const fetchGetCompanyPaymentsSuccess = (payments) => ({
  type: SettingsActionTypes.FETCH_GET_COMPANY_PAYMENTS_SUCCESS,
  payload: payments,
});

export const fetchGetCompanyPayments = () => (dispatch) => {
  dispatch(setFetchingGetGetCompanyPayments(true));

  return new Promise((res, rej) => ApiService.apiCall({
    url: ApiService.paths.company.PAYMENTS,
    isToken: true,
  })
    .then((response) => {
      dispatch(fetchGetCompanyPaymentsSuccess(response.results));
      dispatch(setFetchingGetGetCompanyPayments(false));

      return res(response);
    })
    .catch(({ data }) => {
      dispatch(setFetchingGetGetCompanyPayments(false));

      return rej(data);
    }));
};

export const fetchToggleCompanyPaymentSuccess = (settings) => ({
  type: SettingsActionTypes.FETCH_TOGGLE_COMPANY_PAYMENT_SUCCESS,
  payload: settings,
});

export const fetchToggleCompanyPayments = (id) => (dispatch) => {
  dispatch(setFetchingSettingsSubmitting(true));

  return new Promise((res, rej) => ApiService.apiCall({
    method: 'POST',
    url: ApiService.paths.company.PAYMENTS_METHOD_TOGGLE(id),
    isToken: true,
    data: {},
  })
    .then((response) => {
      dispatch(fetchToggleCompanyPaymentSuccess(response));
      dispatch(setFetchingSettingsSubmitting(false));

      return res(response);
    })
    .catch(({ data }) => {
      dispatch(setFetchingSettingsSubmitting(false));

      return rej(data);
    }));
};
