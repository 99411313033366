import React, { useState } from 'react';
import { timeRegExpDinamic } from 'helpers/RegExp';
import FormField from './FormField';
import { ReactComponent as IconTime } from 'assets/icons/clock.svg';

const FormFieldTime = ({
  onChange = () => {},
  value = '',
  noMessage,
  ...otherProps
}) => {
  const [
    prevValue,
    setPrevValue,
  ] = useState(value);

  const handleChange = (e) => {
    const newValue = getNewValue(prevValue, e.target.value);

    if (newValue !== null) {
      setPrevValue(newValue);
      e.target.value = newValue;

      if (onChange) {
        onChange(e);
      }
    }
  };

  const getNewValue = (prevValue, newValue) => {
    if (newValue === prevValue || !isValid(newValue)) {
      return null;
    }

    if (newValue.length === 1 && +newValue > 2) {
      newValue = `0${newValue}:`;
    }
    else if (newValue.length > 1 && newValue.slice(0, 2) === '24') {
      newValue = '24:00';
    }
    else if (
      newValue.length === 2
          && prevValue.length !== 3
          && !newValue.includes(':')
    ) {
      newValue = `${newValue}:`;
    }

    if (newValue.length === 2 && prevValue.length === 3) {
      newValue = newValue.slice(0, 1);
    }

    return newValue;
  };

  const isValidMinute = (minute) => Number.isInteger(minute) && minute >= 0 && minute < 60;

  const isValidHour = (hour) => Number.isInteger(hour) && hour >= 0 && hour <= 24;

  const isValid = (value) => {
    if (!timeRegExpDinamic.test(value)) {
      return false;
    }

    const [
      hourString = '',
      minuteString = '',
    ] = value.split(':');
    const hours = parseInt(hourString, 2) || 0;
    const minutes = parseInt(minuteString, 2) || 0;

    if (!isValidHour(hours) || !isValidMinute(minutes)) {
      return false;
    }

    if (minutes < 10 && Number(minuteString[0]) > 5) {
      return false;
    }

    return true;
  };

  return (
    <FormField
      {...otherProps}
      onChange={handleChange}
      type="text"
      noMessage={noMessage}
      icon={IconTime}
      iconHeight={20}
      iconWidth={20}
      value={value}
    />
  );
};

export default FormFieldTime;
