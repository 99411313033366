import React, {
  useMemo,
  useState,
} from 'react';
import s from 'styled-components';
import { FormHeading } from 'components/form';
import { AnimateHeight } from 'components/base';
import { Box } from 'layout';
import { Text } from 'components/typo';
import Switcher from 'components/Switcher';

const FormSwitchDropdownItemContainer = s.div`
  position: relative;
  display: block;
  padding-top: 28px;
  padding-bottom: ${({ $value }) => ($value ? 0 : 22)}px;
  min-height: 84px;
  cursor: pointer !important;
  overflow: hidden;
  border-bottom-color: ${({
    theme,
    $bc,
  }) => theme.colors[$bc]};
  border-bottom-width: 1px;
  border-bottom-style: solid;
  transition: padding 0.3s linear;

  &> *:first-child > *:first-child {
    transition: color 0.3s ease-in-out;
  }

  &:hover > *:first-child > *:first-child {
    color: ${({ theme }) => theme.colors.black} !important;
  }

  .drop-content {
    padding-top: 16px;
  }
`;

const FormSwitchDropdownItem = ({
  title,
  children,
  name,
  value,
  label,
  handleChange,
  reversed = false,
}) => {
  const [
    isHovered,
    setHovered,
  ] = useState(false);
  const toggleEventHandler = (e) => {
    e.stopPropagation();
    handleChange();
  };

  const valueMapped = useMemo(() => (reversed ? !value : value), [
    reversed,
    value,
  ]);

  return (
    <FormSwitchDropdownItemContainer
      $bc={valueMapped ? 'transparent' : 'grey200'}
      $value={valueMapped}
      onClick={toggleEventHandler}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <FormHeading
        nomargin
        color={value || reversed ? 'dark' : 'grey500'}
        title={title}
      >
        <Box
          ai="center"
          ac="center"
        >
          {label && (
            <Text
              className="mr-3"
              color={value ? 'black' : 'grey500'}
            >
              {label}
            </Text>
          )}
          <Switcher
            name={name}
            value={value}
            clickOff
            isHovered={isHovered}
          />
        </Box>
      </FormHeading>
      <AnimateHeight show={valueMapped}>
        <Box
          className="drop-content"
          display="block"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {children}
        </Box>
      </AnimateHeight>
    </FormSwitchDropdownItemContainer>
  );
};

export default FormSwitchDropdownItem;
