import { CategoriesActionTypes } from 'redux/types/CategoriesTypes';
import { ProductsActionTypes } from 'redux/types/ProductsTypes';
import { UserActionTypes } from 'redux/types/UserTypes';

const INITIAL_STATE = {
  name: 'categories',
  categories: null,
  isFetchingCategories: true,
  isFetchingToggleActive: false,
  isSubmitting: false,
  catalogOpenedIds: [],
  category: null,
  isCategorySubmitting: false,
};

const categoriesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UserActionTypes.USER_LOGOUT_SUCCESS:
    return { ...INITIAL_STATE };

  case CategoriesActionTypes.SET_CATALOG_CATEGORIES_OPENED_IDS:
    return {
      ...state,
      catalogOpenedIds: action.payload,
    };

  case CategoriesActionTypes.SET_FETCHING_CATEGORIES:
    return {
      ...state,
      isFetchingCategories: action.payload,
    };

  case CategoriesActionTypes.FETCH_CATEGORIES_SUCCESS:
    return {
      ...state,
      categories: action.payload,
    };

  case CategoriesActionTypes.CLEAR_CATEGORIES:
    return {
      ...state,
      categories: null,
    };

  case CategoriesActionTypes.SET_CATEGORY:
  case CategoriesActionTypes.FETCH_GET_CATEGORY_BY_ID_SUCCESS:
    return {
      ...state,
      category: action.payload,
    };

  case CategoriesActionTypes.SET_FETCHING_CATEGORY_SUBMITTING:
    return {
      ...state,
      isCategorySubmitting: action.payload,
    };

  case CategoriesActionTypes.FETCH_CATEGORY_TOGGLE_PRODUCT_ACTIVE_SUCCESS: {
    const getToggledProducts = (products, newProduct) => products.map((p) => (p.id === newProduct.id
      ? newProduct
      : p));

    return {
      ...state,
      categories: state.categories.map((c) => (c.id === action.payload.category
        ? {
          ...c,
          products: getToggledProducts(c.products, action.payload),
        }
        : c)),

    };
  }

  case CategoriesActionTypes.FETCH_CATEGORY_CREATE_SUCCESS: {
    return {
      ...state,
      categories: [
        ...(state.categories || {}),
        action.payload,
      ].sort((a, b) => {
        if (a.position > b.position) return 1;
        if (a.position < b.position) return -1;

        return -1;
      }),
    };
  }

  case CategoriesActionTypes.FETCH_CATEGORY_UPDATE_SUCCESS: {
    return {
      ...state,
      categories: (state.categories || []).map((c) => (c.id === action.payload.id
        ? action.payload
        : c))
        .sort((a, b) => a.position - b.position),
    };
  }

  case CategoriesActionTypes.FETCH_CATEGORY_DELETE_SUCCESS: {
    return {
      ...state,
      categories: state.categories.filter((c) => c.id !== action.payload),
    };
  }

  case CategoriesActionTypes.SET_FETCHING_CATEGORY_TOGGLE_ACTIVE:
    return {
      ...state,
      isFetchingToggleActive: action.payload,
    };

  case CategoriesActionTypes.FETCH_CATEGORY_TOGGLE_ACTIVE_SUCCESS: {
    return {
      ...state,
      categories: state.categories.map(((c) => (c.id === action.payload.id
        ? action.payload
        : c))),
    };
  }

  case ProductsActionTypes.FETCH_PRODUCT_UPDATE_SUCCESS: {
    const {
      product,
      oldProduct,
    } = action.payload;

    const newCategories = JSON.parse(JSON.stringify(state.categories));
    const newCategory = newCategories.find((c) => c.id === product.category);

    const isMove = product.category !== oldProduct.category;

    if (isMove) {
      const oldCategory = newCategories.find((c) => c.id === oldProduct.category);

      if (oldCategory) {
        oldCategory.products = oldCategory.products.filter((p) => p.id !== oldProduct.id);
        oldCategory.products_count -= 1;
      }
      if (newCategory) {
        newCategory.products = [
          ...newCategory.products,
          product,
        ];
        newCategory.products_count += 1;
      }
    }
    else {
      newCategory.products = newCategory.products.map((p) => (p.id === product.id
        ? product
        : p));
    }

    return {
      ...state,
      categories: newCategories,
    };
  }

  case ProductsActionTypes.FETCH_PRODUCT_DELETE_SUCCESS: {
    return {
      ...state,
      categories: state.categories.map((c) => (c.id === action.payload.category
        ? {
          ...c,
          products: c.products.filter((p) => p.id !== action.payload.id),
          products_count: c.products_count - 1,
        } : c)),
    };
  }

  case ProductsActionTypes.FETCH_PRODUCT_CREATE_SUCCESS: {
    return {
      ...state,
      categories: state.categories.map((c) => (c.id === action.payload.category
        ? {
          ...c,
          products: [
            ...c.products,
            action.payload,
          ],
          products_count: c.products_count + 1,
        } : c)),
    };
  }

  default:
    return state;
  }
};

export default categoriesReducer;
