export const colors = {
  brown: 'rgba(137, 38, 34, 1)',
  brown20: 'rgba(137, 38, 34, 0.2)',
  brown40: 'rgba(137, 38, 34, 0.4)',
  green: '#1ecd79',
  greent20: 'rgba(30, 205, 121, 0.2);',
  greent40: 'rgba(30, 205, 121, 0.4)',
  greent80: 'rgba(30, 205, 121, 0.8)',
  orange: '#ff8c39',
  oranget20: 'rgba(255, 140, 57, 0.2);',
  oranget80: 'rgba(255, 140, 57, 0.8);',
  purple: '#8665E3',
  purplet20: 'rgba(134, 101, 227, 0.2)',
  purplet40: 'rgba(134, 101, 227, 0.4)',
  purplet80: 'rgba(134, 101, 227, 0.8)',
  black: '#272727',
  dark: '#272727',
  pink: '#F730B3',
  pink20: 'rgba(247, 48, 179, 0.2)',
  pink80: 'rgba(247, 48, 179, 0.8)',
  black700: '#4A5568',
  black800: '#2D3748',
  blackt20: 'rgba(39, 39, 39, 0.2)',
  blackt40: 'rgba(39, 39, 39, 0.4)',
  blackt50: 'rgba(39, 39, 39, 0.5)',
  blackt60: 'rgba(39, 39, 39, 0.06)',
  blackt80: 'rgba(39, 39, 39, 0.8)',
  blue: '#5AC8FA',
  bluet80: 'rgba(90,200,250, 0.8)',
  bluet20: 'rgba(90, 200, 250, 0.2)',
  white: '#fff',
  whitet20: 'rgba(255, 255, 255, 0.2)',
  whitet40: 'rgba(255, 255, 255, 0.4)',
  whitet70: 'rgba(255, 255, 255, 0.7)',
  whitet90: 'rgba(255, 255, 255, 0.9)',
  red: '#E31D24',
  red20: 'rgba(227, 29, 36, 0.2)',
  red40: 'rgba(227, 29, 36, 0.4)',
  red80: 'rgba(227, 29, 36, 0.8)',
  redDark: '#E31D24',
  greyLight: '#F7F7F7',
  grey100: '#F7F7F7',
  grey200: '#F7F7F7',
  grey300: '#E2E8F0',
  grey400: '#CBD5E0',
  grey400t: 'rgba(203, 213, 224, 0.4)',
  grey500: '#B5B5B5',
  greyDark: '#606060',
  grey500t40: 'rgba(181, 181, 181, 0.4)',
  grey600: 'rgba(99, 99, 102, 1)',
  grey700: '#4a5568',
  grey800: '#2D3748',
  grey900: '#718096',
  modalBg: 'rgba(45, 55, 72, 0.3)',
  inherit: 'inherit',
  current: 'currentColor',
  transparent: 'rgba(0, 0, 0, 0)',
};

export const fontSizes = {
  big: '20px',
  medium: '18px',
  normal: '16px',
  small: '14px',
  xs: '12px',
};

export const fontWeights = {
  b: '700',
  m: '500',
  sb: '600',
  n: '400',
};

const spacers = {
  0: 0,
  1: 4,
  2: 8,
  3: 12,
  4: 16,
  5: 20,
  6: 24,
  7: 28,
  8: 32,
  9: 36,
  10: 40,
};

export const theme = {
  colors,
  spacers,
  fs: fontSizes,
  fw: fontWeights,
  components: {
    container: {
      defaultPadding: '0 15px',
      sizes: {
        normal: { maxWidth: '1282px' },
        full: {
          maxWidth: '1440px',
          padding: 0,
        },
        small: { maxWidth: '1070px' },
        xs: { maxWidth: '860px' },
      },
    },
    ipage: {
      padding: {
        left: 32,
        right: 32,
        top: 32,
        bottom: 32,
        all: '32px 32px 32px 32px',
      },
      maxWidth: 1061,
    },
    sidebar: { width: 286 },
    button: {
      minWidths: {
        normal: '218px',
        medium: '160px',
      },
      sizes: {
        xl: {
          height: '66px',
          padding: '10px 20px',
          offset: '9px',
          spinner: 'normal',
          fs: fontSizes.big,
          fw: fontWeights.sb,
          br: '16px',
        },
        big: {
          height: '59px',
          padding: '10px 25px',
          offset: '9px',
          spinner: 'normal',
          fs: fontSizes.big,
          fw: fontWeights.sb,
          br: '16px',
        },
        normal: {
          height: '46px',
          padding: '8px 22px',
          spinner: 'small',
          offset: '7px',
          fs: fontSizes.normal,
          fw: fontWeights.sb,
          br: '12px',
        },
        small: {
          height: '36px',
          padding: '5px 14px',
          spinner: 'small',
          offset: '7px',
          fs: fontSizes.normal,
          fw: fontWeights.m,
          br: '10px',
        },
      },
      styles: {
        acsent: {
          bg: colors.red,
          bgHover: colors.red80,
          color: colors.white,
          spinnerColor: colors.white,
        },
        green: {
          bg: colors.green,
          bgHover: colors.greent80,
          color: colors.white,
          spinnerColor: colors.white,
        },
        'green-blur': {
          bg: colors.greent80,
          bgHover: colors.green,
          color: colors.white,
          spinnerColor: colors.white,
        },
        orange: {
          bg: colors.orange,
          bgHover: colors.oranget80,
          color: colors.white,
          spinnerColor: colors.white,
        },
        pink: {
          bg: colors.pink,
          bgHover: colors.pink80,
          color: colors.white,
          spinnerColor: colors.white,
        },
        purple: {
          bg: colors.purple,
          bgHover: colors.purplet80,
          spinnerColor: colors.white,
          color: colors.white,
        },
        white: {
          bg: colors.white,
          color: colors.black,
          colorHover: colors.black700,
          colorLoad: colors.grey500,
          spinnerColor: colors.grey500,
        },
        black: {
          bg: colors.black,
          color: colors.white,
          bgHover: colors.black800,
          bgDisabled: colors.grey400,
          opacityDisabled: 1,
          spinnerColor: colors.white,
        },
        blue: {
          bg: colors.blue,
          color: colors.white,
          bgHover: colors.bluet80,
          spinnerColor: colors.white,
        },
        whitegrey: {
          bg: colors.grey200,
          color: colors.black,
          colorHover: colors.black700,
          colorLoad: colors.grey500,
        },
        grey: {
          bg: colors.grey400,
          color: colors.white,
          colorHover: colors.black300,
          colorLoad: colors.white,
          bgDisabled: colors.grey400,
        },
        blured: {
          bg: colors.whitet20,
          bgHover: colors.whitet40,
          color: colors.white,
          blur: 'blur(50px)',
          spinnerColor: colors.grey500,
        },
        bordered: {
          bg: colors.transparent,
          color: colors.black700,
          borderWidth: '1px',
          borderStyle: 'solid',
          border: '1px solid currentColor',
          colorHover: colors.black,
          disabledColor: colors.grey400,
          spinnerColor: colors.black,
          fw: fontWeights.n,
        },
        transparent: {
          bg: colors.transparent,
          color: colors.black,
          colorHover: colors.black700,
          spinnerColor: colors.black,
        },
        link: {
          bg: colors.transparent,
          color: colors.grey500,
          colorHover: colors.black,
          spinnerColor: colors.grey500,
          fw: fontWeights.n,
        },
        'link-black': {
          bg: colors.transparent,
          color: colors.black,
          colorHover: colors.black,
          spinnerColor: colors.black,
          fw: fontWeights.n,
        },
      },
    },
    logo: {
      sizes: {
        big: {
          width: '80px',
          height: '80px',
        },
        normal: {
          width: '66px',
          height: '66px',
        },
        small: {
          width: '48px',
          height: '48px',
        },
      },
      styles: {
        acsent: {
          fill: colors.green,
          fillHover: colors.black,
          color: colors.black,
        },
        light: {
          fill: colors.grey500,
          fillHover: colors.black,
          color: colors.grey500,
          colorHover: colors.black,
        },
        white: {
          fill: colors.white,
          color: colors.white,
        },
        black: {
          fill: colors.black,
          color: colors.black,
        },
      },
    },
    cardPlaceholder: {
      banner: {
        big: {
          width: 288,
          height: 148,
          radius: 16,
        },
        normal: {
          width: 160,
          height: 120,
          radius: 10,
        },
        small: {
          width: 80,
          height: 40,
          radius: 6,
        },
        vertical: {
          width: 100,
          height: 130,
          radius: 16,
        },
      },
      square: {
        xl: {
          width: 100,
          height: 100,
          radius: 16,
        },
        bigRound: {
          width: 66,
          height: 66,
          radius: 50,
        },
        big: {
          width: 60,
          height: 60,
          radius: 10,
        },
        normal: {
          width: 44,
          height: 44,
          radius: 12,
        },
        small: {
          width: 28,
          height: 28,
          radius: 6,
        },
      },
      line: {
        xl: {
          width: null,
          height: 46,
          radius: 12,
        },
        big: {
          width: null,
          height: 28,
          radius: 8,
        },
        normal: {
          width: null,
          height: 16,
          radius: 6,
        },
        small: {
          width: null,
          height: 8,
          radius: 8,
        },
      },
    },
    cardImage: {
      screenshot: {
        width: 298,
        height: 646,
        padding: 0,
        br: 16,
      },
      big: {
        width: 88,
        height: 88,
        padding: 6,
        br: 16,
      },
      medium: {
        width: 66,
        height: 66,
        padding: 8,
        br: 12,
      },
      normal: {
        width: 46,
        height: 46,
        padding: 6,
        br: 10,
      },
      samll: {
        width: 22,
        height: 22,
        padding: 4,
      },
      bannerBig: {
        width: 282,
        height: 148,
        padding: 0,
        br: 16,
      },
      stories: {
        width: 100,
        height: 130,
        padding: 0,
        br: 16,
      },
    },
    dropdown: {
      positions: {
        'top-right': {
          bottom: '100%',
          right: '0',
          left: 'unset',
          marginBottom: '12px',
        },
        'top-left': {
          bottom: '100%',
          left: '0',
          right: 'unset',
          marginBottom: '12px',
        },
        'bottom-right': {
          top: '100%',
          right: '0',
          left: 'unset',
          marginTop: '12px',
        },
        'bottom-left': {
          top: '100%',
          left: '0',
          right: 'unset',
          marginTop: '12px',
        },
      },
    },
    dropzone: {
      color: colors.green,
      border: '1px solid currentColor',
      sizes: {
        square: {
          width: '218px',
          height: '218px',
          br: '16px',
        },
        squareMedium: {
          width: '192px',
          height: '192px',
          br: '16px',
        },
        squareRound: {
          width: '192px',
          height: '192px',
          br: '46px',
          clear: {
            top: '0',
            right: '0',
          },
        },
        banner: {
          width: '358px',
          height: '188px',
          br: '16px',
        },
        stories: {
          width: '218px',
          height: '283px',
          br: '16px',
        },
        screenshots: {
          width: '100%',
          height: 'auto',
          br: '16px',
          padding: '94.5px 210px 90px 210px',
        },
      },
    },
    bage: {
      grey: {
        bg: colors.grey200,
        color: colors.grey500,
      },
      brown: {
        bg: colors.brown20,
        color: colors.brown,
      },
      greyDark: {
        bg: colors.grey500,
        color: colors.white,
      },
      purple: {
        bg: colors.purplet20,
        color: colors.purple,
      },
      blue: {
        bg: colors.bluet20,
        color: colors.blue,
      },
      orange: {
        bg: colors.oranget20,
        color: colors.orange,
      },
      orangeDark: {
        bg: colors.orange,
        color: colors.white,
      },
      green: {
        bg: colors.greent20,
        color: colors.green,
      },
      red: {
        bg: colors.red20,
        color: colors.red,
      },
      pink: {
        bg: colors.pink20,
        color: colors.pink,
      },
    },
    spinner: {
      color: colors.red,
      sizes: {
        big: {
          radius: '44px',
          stroke: '3px',
        },
        medium: {
          radius: '32px',
          stroke: '3px',
        },
        normal: {
          radius: '24px',
          stroke: '2.5px',
        },
        small: {
          radius: '16px',
          stroke: '2px',
        },
      },
    },
    buttonRound: {
      sizes: {
        xl: {
          size: '90px',
          padding: '15px',
        },
        big: {
          size: '66px',
          padding: '15px',
        },
        normal: {
          size: '46px',
          padding: '15px',
        },
        small: {
          size: '36px',
          padding: '11.4px',
        },
        xs: {
          size: '24px',
          padding: '7px',
        },
        xxs: {
          size: '16px',
          padding: '3px',
        },
        reset: {
          size: '30px',
          padding: '7px',
        },
        settings: {
          size: '28px',
          padding: '4px',
        },
      },
      styles: {
        acsent: {
          bg: colors.red,
          bgHover: colors.red80,
          fill: colors.white,
          spinnerColor: colors.white,
        },
        black: {
          bg: colors.black,
          bgHover: colors.blackt80,
          fill: colors.white,
          spinnerColor: colors.white,
        },
        light: {
          bg: colors.grey100,
          fill: colors.black,
          fillHover: colors.black700,
        },
        white: {
          bg: colors.white,
          bgHover: colors.black,
          fill: colors.black,
          fillHover: colors.white,
        },
        dark: {
          bg: colors.blackt20,
          fill: colors.white,
          bgHover: colors.blackt40,
          blur: 'blur(50px)',
        },
        orange: {
          bg: colors.orange,
          fill: colors.white,
          bgHover: colors.oranget80,
        },
        purple: {
          bg: colors.purple,
          fill: colors.white,
          color: colors.white,
          bgHover: colors.purplet80,
          blur: 'blur(50px)',
        },
        green: {
          bg: colors.green,
          fill: colors.white,
          bgHover: colors.greent80,
        },
        settings: {
          bg: colors.transparent,
          fill: colors.grey500,
          fillHover: colors.black,
        },
        transparent: {
          bg: colors.transparent,
          fill: colors.black,
          fillHover: colors.black,
        },
        blured: {
          bg: colors.whitet20,
          bgHover: colors.whitet40,
          color: colors.white,
          fill: colors.white,
          blur: 'blur(50px)',
        },
      },
    },
  },
  sizes: {
    big: '58px',
    'icon-normal': '55px',
    normal: '46px',
    normalField: '44px',
    small: '40px',
    xs: '36px',
    xxs: '28px',
    'square-big': '58px',
    'square-normal': '46px',
    'square-small': '40px',
    list: '28px',
    'square-list': '28px',
  },
  radius: {
    xxl: '60px',
    xl: '30px',
    big: '24px',
    normalBig: '20px',
    normal: '16px',
    medium: '12px',
    small: '10px',
    unset: 'unset',
  },
  paddings: {
    big: '36px',
    normal: '24px',
    'icon-normal': '16px',
    small: '10px',
    unset: 'unset',
    'list-normal': '19px',
    listY: '17px 0',
  },
  cards: { width: { small: '192px' } },
};
