import { SettingsActionTypes } from 'redux/types/SettingsTypes';
import { UserActionTypes } from 'redux/types/UserTypes';

const INITIAL_STATE = {
  name: 'settings',
  isSubmitting: false,
  isFetching: false,
  countries: [],
  payments: null,
};

const settingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case UserActionTypes.USER_LOGOUT_SUCCESS:
    return { ...INITIAL_STATE };

  case SettingsActionTypes.SET_FETCHING_SETTINGS_SUBMITTING:
    return {
      ...state,
      isSubmitting: action.payload,
    };

  case SettingsActionTypes.FETCH_GET_COMPANY_PAYMENTS_SUCCESS:
    return {
      ...state,
      payments: action.payload,
    };

  case SettingsActionTypes.FETCH_TOGGLE_COMPANY_PAYMENT_SUCCESS:
    return {
      ...state,
      payments: [
        ...state.payments.map((p) => (p.id === action.payload.id
          ? action.payload
          : p)),
      ],
    };

  case SettingsActionTypes.SET_FETCHING_GET_COUNTRIES:
  case SettingsActionTypes.SET_FETCHING_SETTINGS_DATA:
  case SettingsActionTypes.SET_FETCHING_GET_COMPANY_PAYMENTS:
    return {
      ...state,
      isFetching: action.payload,
    };

  case SettingsActionTypes.FETCH_GET_COUNTRIES_SUCCESS:
    return {
      ...state,
      countries: action.payload,
    };

  default:
    return state;
  }
};

export default settingsReducer;
