import React, { forwardRef } from 'react';
import s from 'styled-components';
import cn from 'classnames';

export const Styles = ({
  theme,
  $size,
  $square,
  $defaultStyle,
  $minHeight,
}) => `
  font-size: ${$size === 'big' ? theme.fs.medium : theme.fs.normal};
  display: flex;
  min-height ${$minHeight ? `${$minHeight} !important` : ''};
  height: ${$size && theme.sizes[$size] ? theme.sizes[$size] : theme.sizes.normal};
  width: ${$square ? theme.sizes[$size] : '100%'};
  align-items: stretch;
  padding: 11px 16px;
  border: 1px solid ${$defaultStyle === 'simple' ? 'unset' : theme.colors.grey100};
  background-color: ${$defaultStyle === 'simple' ? 'unset' : theme.colors.grey100};
  transition: ${$defaultStyle === 'simple' ? 'unset' : 'border-color 0.3s ease-in-out, background-color 0.3s ease-in-out'};
  border-radius: 10px;

  &:hover {
    border-color: ${$defaultStyle === 'simple' ? 'unset' : theme.colors.grey300};
  }

  &.--error:focus,
  &:focus {
    background-color: ${$defaultStyle === 'simple' ? 'unset' : theme.colors.white};
    border-color: ${$defaultStyle === 'simple' ? 'unset' : theme.colors.black};
    color: ${$defaultStyle === 'simple' ? 'unset' : theme.colors.black};
  }

  &.--bordered {
    width: 100%;
    border: 1px solid #E3E3E3;
    padding: 13px 18px;
    border-radius: 5px;
  }

  &.--error {
    background-color: ${theme.colors.white};
    border-color: ${theme.colors.red} !important;
  }

  &:disabled {
    color: ${$defaultStyle === 'simple' ? 'unset' : theme.colors.grey500};
  }

  &.confirmation-code {
    @media (max-width: 400px) {
      max-width: ${theme.sizes[$size]} !important;
      width: 100% !important;
      margin-right: 12px !important;

      &:last-child {
        margin-right: 0 !important;
      }
    }
  }
`;

const InputContainer = s.input`
  ${(props) => Styles(props)}
`;

const InputContainerTextarea = s.textarea`
  ${(props) => Styles(props)}
`;

const Input = forwardRef(({
  tag = false,
  defaultStyle,
  className,
  size = 'normal',
  autocomplete,
  isError,
  minHeight = '',
  square,
  ...otherProps
}, ref) => {
  const classes = cn('w-input', {
    [`--${defaultStyle}`]: defaultStyle,
    '--error': isError,
  }, className);

  const props = {
    ...otherProps,
    ref,
    $size: size,
    $square: square,
    className: classes,
    $defaultStyle: defaultStyle,
    $minHeight: minHeight,
  };

  const Input = tag === 'textarea'
    ? InputContainerTextarea
    : InputContainer;

  return <Input {...props} />;
});

Input.displayName = 'Input';

export default Input;
