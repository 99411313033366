import {
  format,
  parse,
  isToday,
  isSameDay,
  getMonth,
  getDaysInMonth,
  addMonths,
  addDays,
  startOfMonth,
} from 'date-fns';
import ruLocale from 'date-fns/locale/ru';

export const MONTHS = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

export const QUARTS = [
  '1 квартал',
  '1 квартал',
  '1 квартал',
  '2 квартал',
  '2 квартал',
  '2 квартал',
  '3 квартал',
  '3 квартал',
  '3 квартал',
  '4 квартал',
  '4 квартал',
  '4 квартал',
];

const mainDateFormatterShort = 'dd MMMM';
const mainDateFormatter = `${mainDateFormatterShort} yyyy`;

export const DATE_FORMAT_MAIN = mainDateFormatter;
export const DATE_FORMAT_WITH_TIME = 'dd.MM.yyyy в HH:mm';
export const DATE_FORMAT_DELETED = 'dd.MM.yyyy, HH:mm';
export const DATE_FORMAT_DATEPICKER = 'yyyy-MM-dd';

export const formatDateDayAndMonth = (date) => format(new Date(date), 'd MMMM', { locale: ruLocale });

export const formatDateDayMonthYear = (date) => format(new Date(date), 'd MMMM yyyy', { locale: ruLocale });

export const formatDate = (date, dateFormat = 'MM.dd.yyyy') => (date ? format(new Date(date), dateFormat, { locale: ruLocale }) : null);

export const formatDateOrToday = (date, dateFormat = 'dd.MM.yy') => {
  const dateFormated = new Date(date);
  const today = isToday(dateFormated);

  if (today) return 'cегодня';

  return format(dateFormated, dateFormat, { locale: ruLocale });
};

export const formatDateToQuery = (date, dateFormat = 'yyyy-MM-dd') => formatDate(date, dateFormat);

export const formatTime = (time, formater = 'HH:mm') => format(parse(time, 'HH:mm:ss', new Date()), formater);

export const dateIsExpired = (date) => (new Date()
  .getTime() > new Date(date)
  .getTime());

export const isInOneDay = (first, second) => isSameDay(new Date(first), new Date(second));

export const renderDateOrToday = (date, formatter = 'd MMMM yyyy') => {
  const formatedDate = new Date(date);

  if (isToday(formatedDate)) {
    return 'Сегодня';
  }

  return formatDate(formatedDate, formatter);
};

export const parseDate = (str) => {
  const mdy = str.split('/');

  return new Date(mdy[2], mdy[0] - 1, mdy[1]);
};

export const getDaysDiff = (first, second) => Math.round((second - first) / (1000 * 60 * 60 * 24));

export const getEventSentDate = (date = '', time = '') => `${formatDate(date, 'yyyy-MM-dd')}T${time}`;

export const getDateMonth = (date) => (date ? getMonth(date, { locale: ruLocale }) : null);

export const getMonthDays = (month) => (getDaysInMonth(month, { locale: ruLocale }));

export const addMonthCount = (date, num) => (addMonths(date, num));

export const addDaysCount = (date, num) => (addDays(date, num));

export const getStartOfMonth = (month) => (startOfMonth(month || new Date(Date.now()), { locale: ruLocale }));
